import React, { createContext, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import type { ImageDataLike } from 'gatsby-plugin-image'
import type { View } from 'types/view'
import type { TextContent } from 'types/textContent'
import { temporaryTextContent } from 'utils/temporaryTextContent'

type ViewContextProps = {
  currentView: View
  pickupStationImg: ImageDataLike
  pickupPointImg: ImageDataLike
  courierImg: ImageDataLike
  textContent: TextContent
  animateAll: boolean
  changeView: (view: View) => void
  startAnimateAll: () => void
}

export const ViewContext = createContext<ViewContextProps | null>(null)

export const ViewProvider: React.FC = ({ children }) => {
  const [currentView, setCurrentView] = useState<View>('startingView')
  const [animateAll, setAnimateAll] = useState(false)

  const startAnimateAll = () => setAnimateAll(true)

  const data = useStaticQuery<{
    pickupStationImg?: { childImageSharp?: { gatsbyImageData: ImageDataLike } }
    pickupPointImg?: { childImageSharp?: { gatsbyImageData: ImageDataLike } }
    courierImg?: { childImageSharp?: { gatsbyImageData: ImageDataLike } }
    // wpPage: {
    //   onepage: TextContent
    // }
  }>(graphql`
    query Images {
      pickupStationImg: file(name: { eq: "delivery-type-pickup-station" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
        }
      }
      pickupPointImg: file(name: { eq: "delivery-type-pickup" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
        }
      }
      courierImg: file(name: { eq: "delivery-type-courier" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
        }
      }
      # wpPage {
      #   onepage {
      #     mainPageWelcome
      #     mainPageCommunication
      #     parcelMachineHowToUseDescription
      #     parcelMachineCommuniactionIcon1
      #     parcelMachineCommuniactionIcon2
      #     parcelMachineCommuniactionIcon3
      #     pickupPointHowToUseDescription
      #     pickupPointCommuniactionIcon1
      #     pickupPointCommuniactionIcon2
      #     pickupPointCommuniactionIcon3
      #     courierHowToUseDescription
      #     courierCommuniactionIcon1
      #     courierCommuniactionIcon2
      #     courierCommuniactionIcon3
      #     appTitle
      #     appDescription
      #     appSend
      #   }
      # }
    }
  `)

  const changeView = (view: View) => setCurrentView(view)

  return (
    <ViewContext.Provider
      value={{
        currentView,
        pickupStationImg:
          data?.pickupStationImg.childImageSharp.gatsbyImageData!,
        pickupPointImg: data?.pickupPointImg.childImageSharp.gatsbyImageData!,
        courierImg: data?.courierImg.childImageSharp.gatsbyImageData!,
        textContent: temporaryTextContent,
        animateAll,
        changeView,
        startAnimateAll,
      }}
    >
      {children}
    </ViewContext.Provider>
  )
}

export default ViewProvider
