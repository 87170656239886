import React, { createContext, useState, useMemo } from 'react'

import { getDeliveryProps } from 'constants/deliveryTypes'

import { Delivery, DeliveryContent } from 'types/delivery'

import useView from 'hooks/useView'

import { ImageDataLike } from 'gatsby-plugin-image'

type DeliverySlide = {
  src: ImageDataLike
  id: Delivery
}

type DeliveryContextProps = {
  options: readonly ['pickupStation', 'pickupPoint', 'courier']
  available: Delivery[]
  availableSlides: DeliverySlide[]
  selected: Delivery[]
  lastSelected: DeliveryContent | null

  selectDeliveryType: (id: Delivery) => void
  resetSelectedDeliveryTypes: () => void
}

export const DeliveryContext = createContext<DeliveryContextProps | null>(null)

export const DeliveryProvider: React.FC = ({ children }) => {
  const { pickupStationImg, pickupPointImg, courierImg } = useView()

  const [selected, setSelected] = useState<Delivery[]>([])
  const options = ['pickupStation', 'pickupPoint', 'courier'] as const
  const slides = [
    { src: pickupPointImg, id: 'pickupPoint' as Delivery },
    { src: pickupStationImg, id: 'pickupStation' as Delivery },
    { src: courierImg, id: 'courier' as Delivery },
  ]

  function shiftElementToLastPlace(array: any) {
    const arr = [...array]
    arr.push(arr.shift())
    return arr
  }

  const available = useMemo(
    () => options.filter((type) => !selected.includes(type)),
    [selected]
  )

  const availableSlides = useMemo(() => {
    const slidesLeft = slides.filter(({ id }) => !selected.includes(id))
    const reversed = shiftElementToLastPlace(slidesLeft)

    if (
      slidesLeft.length === 2 &&
      slidesLeft[0].id !== 'courier' &&
      slidesLeft[1].id !== 'courier'
    ) {
      return slidesLeft
    }

    return slidesLeft.length === 2 ? reversed : slidesLeft
  }, [selected])

  const lastSelected = useMemo(
    () =>
      selected.length === 0
        ? null
        : getDeliveryProps(selected[selected.length - 1]),
    [selected]
  )

  const selectDeliveryType = (type: Delivery) => {
    setSelected([...selected, type])
  }

  const resetSelectedDeliveryTypes = () => {
    setSelected([])
  }

  return (
    <DeliveryContext.Provider
      value={{
        options,
        selected,
        available,
        availableSlides,
        lastSelected,
        selectDeliveryType,
        resetSelectedDeliveryTypes,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  )
}

export default DeliveryProvider
