/* eslint-disable */

import type { TextContent } from 'types/textContent'

export const temporaryTextContent: TextContent = {
  mainPageWelcome: 'Cześć!',
  mainPageCommunication:
    'W DPD to Ty decydujesz, <br/> jak doręczymy Twoją paczkę!',
  appTitle: 'W DPD to Ty decydujesz,</br> jak doręczymy Twoją paczkę!',
  appDescription:
    'Pobierz aplikację DPD Mobile </br>i w wygodny sposób zarządzaj wysyłką </br>i odbiorem paczek',
  appSend:
    'https://nadaj.dpd.com.pl/?utm_source=www&utm_medium=mojedpdteaser&utm_campaign=own',
  parcelMachineHowToUseDescription:
    'Dostawę do automatu wybierzesz bezpośrednio w sklepie internetowym, jeśli udostępnia taką opcję.</br></br> Możesz również przekierować paczkę <br/> do automatu, gdy wiesz, że nie będzie <br/> Cię w domu.',
  parcelMachineCommuniactionIcon1: 'Dostępność</br> 24/7',
  parcelMachineCommuniactionIcon2: 'Szybka obsługa </br>przez aplikację',
  parcelMachineCommuniactionIcon3: 'Bez kolejki',
  pickupPointHowToUseDescription:
    'Dostawę do Punktu DPD Pickup wybierzesz bezpośrednio w sklepie internetowym, jeśli udostępnia taką opcję.</br></br> Możesz również przekierować paczkę <br/> do punktu, gdy wiesz, że nie będzie <br/> Cię w domu.',
  pickupPointCommuniactionIcon1: 'Zawsze blisko </br>Ciebie',
  pickupPointCommuniactionIcon2: 'Przyjazna obsługa </br>przesyłek',
  pickupPointCommuniactionIcon3: 'Tak, jak Ci wygodnie',
  courierHowToUseDescription:
    'Dostawę kurierem wybierzesz bezpośrednio w sklepie internetowym. 1 z 10 000 naszych kurierów doręczy <br/> Twoją paczkę. </br></br> Możesz również przekierować paczkę do automatu lub punktu, gdy wiesz, <br/> że nie będzie Cię w domu.',
  courierCommuniactionIcon1: 'Telefoniczny kontakt </br>z Twoim kurierem',
  courierCommuniactionIcon2: 'Bez wychodzenia</br> z domu',
  courierCommuniactionIcon3: 'Elastyczne </br>zarządzanie dostawą',
}
