import type { Breakpoints, Colors, Fonts } from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xs: 0,
  s: 375,
  xsm: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1760,
}

export const colors: Colors = {
  primary: '#A51038',
  primary100: '#C4213C',
  primary200: '#A40F37',
  white: '#FFFFFF',
  black: '#000000',
}

export const fonts: Fonts = {
  size: '10px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  media: media(),
}

export default theme
