import { css, createGlobalStyle } from 'styled-components'

import Normalize from 'styles/normalize'

const Global = css`
  * {
    outline-color: ${({ theme }) => theme.colors.primary};
  }

  html {
    font-size: ${({ theme }) => theme.fonts.size};
  }

  body {
    font-family: 'PlutoSansDPD', sans-serif;
    position: relative;
    overflow: hidden;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: var(—-app-height);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    font-family: 'PlutoSansDPD', sans-serif;
  }

  p,
  span {
    width: 100%;
    font-family: 'PlutoSansDPD', sans-serif;
  }

  button {
    font-family: 'PlutoSansDPD', sans-serif;
  }

  img {
    margin: 0;
  }
`

const GlobalStyles = createGlobalStyle`
    ${Normalize}
    ${Global} 
`

export default GlobalStyles
