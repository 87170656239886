import { ContextType, useContext } from 'react'

import { ViewContext } from 'context/view'

const useView = (): NonNullable<ContextType<typeof ViewContext>> => {
  const context = useContext(ViewContext)

  if (!context) {
    throw new Error('useDeliveryTypes must be used inside DeliveryProvider.')
  }

  return context
}

export default useView
