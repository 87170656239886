/* eslint-disable import/prefer-default-export */

import pickupStationIcon from 'assets/icons/delivery-type-pickup-station.png'
import pickupPointIcon from 'assets/icons/delivery-type-pickup.png'
import courierIcon from 'assets/icons/delivery-type-courier.png'

import benefit24h from 'assets/icons/benefits/24h.svg'
import benefitMobile from 'assets/icons/benefits/mobile.svg'
import benefitCall from 'assets/icons/benefits/call.svg'
import benefitCertificate from 'assets/icons/benefits/certificate.svg'
import benefitCheckbox from 'assets/icons/benefits/checkbox.svg'
import benefitCity from 'assets/icons/benefits/city.svg'
import benefitHome from 'assets/icons/benefits/home.svg'
// import benefitRoundtrip from 'assets/icons/benefits/roundtrip.svg'
import benefitMarker from 'assets/icons/benefits/marker.svg'

import { Delivery } from 'types/delivery'
import useView from 'hooks/useView'

export const getDeliveryProps = (type: Delivery) => {
  const {
    textContent: {
      parcelMachineHowToUseDescription,
      parcelMachineCommuniactionIcon1,
      parcelMachineCommuniactionIcon2,
      parcelMachineCommuniactionIcon3,
      pickupPointHowToUseDescription,
      pickupPointCommuniactionIcon1,
      pickupPointCommuniactionIcon2,
      pickupPointCommuniactionIcon3,
      courierHowToUseDescription,
      courierCommuniactionIcon1,
      courierCommuniactionIcon2,
      courierCommuniactionIcon3,
    },
  } = useView()

  const DELIVERY_TYPES = {
    pickupStation: {
      id: 'pickupStation',
      icon: pickupStationIcon,
      label: 'Automat paczkowy',
      labelTop: 'Automat paczkowy',
      description: parcelMachineHowToUseDescription,
      benefitLabelTop: 'Automat paczkowy',
      benefits: [
        {
          icon: benefit24h,
          text: parcelMachineCommuniactionIcon1,
        },
        {
          icon: benefitMobile,
          text: parcelMachineCommuniactionIcon2,
        },

        {
          icon: benefitCheckbox,
          text: parcelMachineCommuniactionIcon3,
        },
      ],
    },

    pickupPoint: {
      id: 'pickupPoint',
      icon: pickupPointIcon,
      label: 'Punkt DPD Pickup',
      labelTop: 'Punkt DPD Pickup',
      description: pickupPointHowToUseDescription,
      benefitLabelTop: 'Punkt DPD Pickup',
      benefits: [
        {
          icon: benefitMarker,
          text: pickupPointCommuniactionIcon1,
        },
        {
          icon: benefitCertificate,
          text: pickupPointCommuniactionIcon2,
        },

        {
          icon: benefitCity,
          text: pickupPointCommuniactionIcon3,
        },
      ],
    },

    courier: {
      id: 'courier',
      icon: courierIcon,
      label: 'Kurier',
      labelTop: 'Kurier',
      description: courierHowToUseDescription,
      benefitLabelTop: 'Kurier',
      benefits: [
        {
          icon: benefitCall,
          text: courierCommuniactionIcon1,
        },
        {
          icon: benefitHome,
          text: courierCommuniactionIcon2,
        },
        {
          icon: benefitMobile,
          text: courierCommuniactionIcon3,
        },
      ],
    },
  }

  return DELIVERY_TYPES[type]
}
